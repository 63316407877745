<template>
  <validation-observer ref="simpleRules">
    <div>
      <b-card>
        <div class="d-lg-flex justify-content-between align-items-center py-1">
          <h3 class="font-weight-bolder">
            {{ $t('depositAccount') }}
          </h3>
          <div class="income-order-search order-search">
            <div class="anan-input search-input">
              <div class="anan-input__inner anan-input__inner--normal">
                <input
                  v-model="filter"
                  type="text"
                  :placeholder="$t('search')"
                  class="anan-input__input"
                >
                <div class="anan-input__suffix">
                  <i class="anan-input__clear-btn anan-icon" />
                  <i class="anan-input__suffix-icon anan-icon">
                    <i class="fal fa-search" />
                  </i>
                </div>
              </div>
            </div>
          </div>
        </div>

        <button
          class="anan-button--xl-large anan-button anan-button--primary anan-button--normal"
          @click="showAddNew()"
        >
          <span> {{ $t('addDepositAccount') }} </span>
        </button>
        <b-overlay
          :show="showOver"
          variant="transparent"
          opacity="0.99"
          blur="5px"
          rounded="sm"
        >
          <template #overlay>
            <div class="text-center">
              <p id="cancel-label">
                กรุณารอสักครู่...
              </p>
            </div>
          </template>
          <b-table
            striped
            hover
            responsive
            :total-rows="totalRows"
            :per-page="perPage"
            :items="items"
            :fields="fields"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :sort-direction="sortDirection"
            :filter="filter"
            :filter-included-fields="filterOn"
            show-empty
          >
            <template #empty>
              <div class="text-center">
                <img
                  src="@/assets/images/anan-img/empty/empty.png"
                  alt="empty"
                  width="180px"
                >
                <p>No Data</p>
              </div>
            </template>

            <template #cell(number)="data">
              <b-link :to="{name: 'admin-role-edit', params: {id: data.item.id}}">
                ALI-ACC-{{ items.length - (perPage * (currentPage - 1) + (data.index + 1)) + 1 }}
              </b-link>
            </template>

            <template #cell(index)="data">
              {{ perPage * (currentPage - 1) + (data.index + 1) }}
            </template>

            <template #cell(sequence)="data">
              <b-form-input
                v-model="data.item.sequence"
                type="number"
                class="text-center"
                @input="CheckUpdate(data.item.sequence, 'sequence',data.item.id)"
              />
            </template>

            <template #cell(active)="data">
              <b-form-checkbox
                v-model="data.item.active"
                name="payShippingOrigin"
                switch
                inline
                :value="1"
                :unchecked-value="0"
                @change="CheckUpdate(data.item.active, 'active',data.item.id)"
              />
            </template>

            <template #cell(consign_send)="data">
              <b-form-checkbox
                v-model="data.item.consign_send"
                name="payShippingOrigin"
                switch
                inline
                :value="1"
                :unchecked-value="0"
                @change="CheckUpdate(data.item.consign_send, 'consign_send',data.item.id)"
              />
            </template>

            <template #cell(actions)="data">
              <feather-icon
                icon="Edit3Icon"
                class="mr-1 text-primary cursor-pointer"
                @click="showAddNew(data.item)"
              />

              <feather-icon
                class="text-danger cursor-pointer"
                icon="TrashIcon"
                @click="DeleteData(data.item)"
              />
            </template>
          </b-table>
        </b-overlay>
        <div class="demo-spacing-0 d-flex justify-content-end">
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            first-number
            last-number
            prev-class="prev-item"
            next-class="next-item"
            class="mb-0"
            @input="getData()"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>
        </div>
      </b-card>
    </div>

    <b-modal
      id="modal-add-new"
      :title="$t('addDepositAccount')"
      :ok-title="$t('save')"
      :cancel-title="$t('cancel')"
      @ok="submitAddNew()"
    >
      <b-form-group>
        <!-- <validation-provider
          #default="{ errors }"
          name="Bank Name"
          rules="required"
        > -->
          <label for="bank-name">{{ $t('accountName') }} : </label>
          <b-form-input
            id="bank-name"
            v-model="Obj.acc_name"
            type="text"
            :placeholder="$t('accountName')"
          />
            <!-- :state="errors.length > 0 ? false:null" -->
          <!-- <span class="text-danger">{{ errors[0] }}</span>
        </validation-provider> -->
      </b-form-group>

      <b-form-group>
          <label for="acc-email">{{ $t('key-80') }} : </label>
          <b-form-input
            id="acc-email"
            v-model="Obj.email"
            type="text"
            :placeholder="$t('key-80')"
          />
      </b-form-group>

      <b-form-group>

          <label for="acc-title">{{ $t('prefix') }} : </label>
          <b-form-input
            id="acc-title"
            v-model="Obj.prefix"
            type="text"
            :placeholder="$t('prefix')"
          />
      </b-form-group>

      <b-form-group>
        <b-form-checkbox
          v-model="Obj.active"
          name="check-button"
          :value="1"
          :unchecked-value="0"
          switch
          inline
        >
          {{ $t('active') }}
        </b-form-checkbox>
      </b-form-group>

      <b-form-group>
        <b-form-checkbox
          v-model="Obj.consign_send"
          name="check-button"
          :value="1"
          :unchecked-value="0"
          switch
          inline
        >
          {{ $t('depositAmount') }}
        </b-form-checkbox>
      </b-form-group>
    </b-modal>
  </validation-observer>
</template>

<script>
import {
  BTable, BPagination, BFormCheckbox, BCard, BLink, BModal, BFormInput, BFormGroup, BOverlay,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'

export default {
  components: {
    BPagination,
    BCard,
    BTable,
    BLink,
    BModal,
    BFormInput,
    ValidationProvider,
    ValidationObserver,
    BFormCheckbox,
    BFormGroup,
    BOverlay,
  },
  filters: {
    formatBankAccount(value) {
      const formattedNumber = value.replace(/(\d{3})(\d{1})(\d{5})(\d{1})/, '$1-$2-$3-$4')
      return formattedNumber
    },
  },
  data() {
    return {
      currentPage: 1,
      perPage: 10,
      bankAccountNumber: '1271085105',
      required,
      type: 'bank',
      search: '',
      isActive: 'all',
      totalRows: 0,
      filter: null,

      items: [],
      itemBank: [
        { title: 'ธนาคารกสิกรไทย', image: '/bankIcon/KBANK.png' },
        { title: 'ธนาคารไทยพานิชญ์', image: '/bankIcon/scb.png' },
        { title: 'ธนาคารกรุงศรีอยุธยา', image: '/bankIcon/BAY.png' },
        { title: 'ธนาคารกรุงไทย', image: '/bankIcon/KTB.png' }],
      productlist: [
        { text: 'รถ', value: 1 },
        { text: 'เรือ', value: 2 },
      ],
      productDate: {
        id: null,
        name: null,
        detail: null,
        type: null,
        kilo: 0,
        queue: 0,
      },
      Obj: {
        id: null,
        acc_name: null,
        email: null,
        prefix: null,
        active: 0,
        consign_send: 0,
        agent_id: null,
        showOver: false,
      },
    }
  },
  computed: {
    fields() {
      return [
        {
          key: 'number', label: this.$t('number'), thStyle: { width: '10%' }, thClass: 'text-center',
        },
        {
          key: 'sequence', label: this.$t('sequence'), sortable: false, thStyle: { width: '10%' }, thClass: 'text-center',
        },
        {
          key: 'acc_name', label: this.$t('accountName'), sortable: false, thStyle: { width: '15%' }, thClass: 'text-center',
        },
        {
          key: 'prefix', label: this.$t('prefix'), sortable: false, thStyle: { width: '30%' }, thClass: 'text-center',
        },
        {
          key: 'email', label: this.$t('key-80'), sortable: false, thStyle: { width: '10%' }, thClass: 'text-center', tdClass: 'text-center',
        },
        {
          key: 'active', label: this.$t('active'), sortable: false, thStyle: { width: '10%' }, thClass: 'text-center', tdClass: 'text-center',
        },
        {
          key: 'consign_send', label: this.$t('depositAmount'), sortable: false, thStyle: { width: '10%' }, thClass: 'text-center', tdClass: 'text-center',
        },
        {
          key: 'actions', label: this.$t('actions'), sortable: false, thStyle: { width: '5%' }, thClass: 'text-center', tdClass: 'text-center',
        },
      ]
    },
  },
  mounted() {
    this.getData('all')
  },
  methods: {
    showAddNew(item) {
      this.$bvModal.show('modal-add-new')
      if (item) {
        this.Obj.id = item.id
        this.Obj.acc_name = item.acc_name
        this.Obj.email = item.email
        this.Obj.prefix = item.prefix
        this.Obj.active = item.active
        this.Obj.consign_send = item.consign_send
      } else {
        this.Obj.id = null
        this.Obj.acc_name = null
        this.Obj.email = null
        this.Obj.prefix = null
        this.Obj.active = 0
        this.Obj.consign_send = 0
      }
    },
    async getData(type) {
      this.isActive = type
      this.showOver = true
      try {
        const params = {
          perPage: this.perPage,
          page: this.currentPage,
        }
        const { data: res } = await this.$http.get('/alipay/alipay_showall', { params })
        // console.log(res)
        if (res.status) {
          this.items = res.data.data
          this.totalRows = res.data.total
        }
        this.showOver = false
      } catch (e) {
        console.log(e)
        this.showOver = false
      }
    },
    EditRole(data) {
      this.$router.push({ name: 'admin-role-edit', params: { id: data.id } })
    },
    CheckUpdate(data, fields, ID) {
      console.log(data, fields, ID)
      if (data || data === 0) {
        const obj = {
          data,
          fields,
          ID,
        }
        this.$http.post('/alipay/alipay_update_filed', obj)
      }
    },
    DeleteData(data) {
      this.$bvModal.msgBoxConfirm('คุณต้องการลบข้อมูลใช่หรือไม่?', {
        title: 'ยืนยันการลบข้อมูล',
        size: 'sm',
        buttonSize: 'sm',
        okVariant: 'danger',
        okTitle: 'YES',
        cancelTitle: 'NO',
        footerClass: 'p-2',
        hideHeaderClose: false,
        centered: true,
      })
        .then(value => {
          if (value) {
            const params = {
              id: data.id,
            }
            this.$http.post('/alipay/alipay_delete', params).then(() => {
              this.getData(this.isActive)
              this.$bvToast.toast('ลบข้อมูลสำเร็จ', {
                title: 'สำเร็จ',
                variant: 'success',
                solid: true,
                autoHideDelay: 1500,
              })
            })
          }
        })
        .catch(err => {
          console.log(err)
        })
    },
    submitNew() {
      const obj = {
        id: this.productDate.id,
        name: this.productDate.name,
        detail: this.productDate.detail,
        type: this.productDate.type,
        kilo: this.productDate.kilo,
        queue: this.productDate.queue,
      }
      this.$http
        .post('/product_type/product_update', obj)
        .then(() => {
          this.$bvToast.toast('บันทึกข้อมูลสำเร็จ', {
            title: 'Success',
            variant: 'success',
            solid: true,
          })
        })
        .catch(error => {
          console.log(error)
          // this.SwalError(error.response.data.message)
        })
    },
    submitAddNew() {
      console.log(this.Obj)
      const obj = {
        id: this.Obj.id,
        acc_name: this.Obj.acc_name,
        email: this.Obj.email,
        prefix: this.Obj.prefix,
        active: this.Obj.active,
        consign_send: this.Obj.consign_send,
      }
      this.Obj.sequence = 0
      this.$http
        .post('/alipay/alipay_update', obj)
        .then(() => {
          this.getData(this.isActive)
          this.$bvToast.toast('บันทึกข้อมูลสำเร็จ', {
            title: 'Success',
            variant: 'success',
            solid: true,
          })
        })
        .catch(error => {
          console.log(error)
          // this.SwalError(error.response.data.message)
        })
    },

  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
